import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import CurrentlyPlaying from './CurrentlyPlaying';

function Profile() {
  return (
    <section id="profile">
      <div className="section__pic-container">
        <img src="./assets/profile-pic.png" alt="Omar Khan" />
      </div>
      <div className="section__text">
        <p className="section__text__p1">Hello, I'm</p>
        <h1 className="title">Omar Khan</h1>
        <div className="typing-effect-container">
          <ReactTypingEffect
            text={["A Software Engineer", "A Problem Solver", "A Tech Enthusiast"]}
            speed={100}
            eraseSpeed={100}
            typingDelay={100}
            eraseDelay={1000}
            cursorRenderer={cursor => <h2>{cursor}</h2>}
            displayTextRenderer={(text, i) => (
              <h2>{text}</h2>
            )}
          />
        </div>
        <div className="btn-container">
          <button className="btn btn-color-2" onClick={() => window.open('./assets/Omar_Khan_Resume.pdf')}>
            Download Resume
          </button>
          <button className="btn btn-color-1" onClick={() => document.getElementById('contact').scrollIntoView()}>
            Contact Info
          </button>
        </div>
        <div id="socials-container">
          <a href="https://linkedin.com/in/omarkhan3701/" target="_blank" rel="noopener noreferrer">
            <img src="./assets/linkedin.png" alt="LinkedIn" className="icon" />
          </a>
          <a href="https://github.com/omaaarkhan" target="_blank" rel="noopener noreferrer">
            <img src="./assets/github.png" alt="GitHub" className="icon" />
          </a>
          <a href="https://www.instagram.com/omaaarkhan/" target="_blank" rel="noopener noreferrer">
            <img src="./assets/instagram.png" alt="Instagram" className="icon" />
          </a>
        </div>
        <CurrentlyPlaying />
      </div>
    </section>
  );
}

export default Profile;