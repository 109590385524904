// components/Experience.js
import React from 'react';

function Experience() {
  return (
    <section id="experience">
      <p className="section__text__p1">Explore My</p>
      <h1 className="title">Experience</h1>
      <div className="experience-details-container">
        <div className="about-containers">
          <div className="details-container">
            <h2 className="experience-sub-title">Frontend Development</h2>
            <div className="article-container">
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>React</h3>
                  <p>Advanced</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>HTML / CSS</h3>
                  <p>Advanced</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>WPF</h3>
                  <p>Advanced</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>JavaScript</h3>
                  <p>Basic</p>
                </div>
              </article>
            </div>
          </div>
          <div className="details-container">
            <h2 className="experience-sub-title">Backend Development</h2>
            <div className="article-container">
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>Python</h3>
                  <p>Advanced</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>C</h3>
                  <p>Advanced</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>C++</h3>
                  <p>Advanced</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>Java</h3>
                  <p>Intermediate</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>C# / .NET</h3>
                  <p>Intermediate</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>Haskell</h3>
                  <p>Intermediate</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>Prolog</h3>
                  <p>Intermediate</p>
                </div>
              </article>
              <article>
                <img src="/assets/checkmark.png" alt="Experience icon" className="icon" />
                <div>
                  <h3>SQL/MySQL</h3>
                  <p>Basic</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <img 
        src="/assets/arrow.png" 
        alt="Arrow icon" 
        className="icon arrow" 
        onClick={() => document.getElementById('projects').scrollIntoView()} 
      />
    </section>
  );
}

export default Experience;