import React, { useState, useEffect } from 'react';

const LASTFM_API_KEY = '3261bc8e0e76e5d69da25010f7ccf8ac';
const LASTFM_USERNAME = 'oxMARxo';

function CurrentlyPlaying() {
  const [lastTrack, setLastTrack] = useState(null);

  useEffect(() => {
    const fetchLastTrack = async () => {
      try {
        const response = await fetch(`https://ws.audioscrobbler.com/2.0/?method=user.getrecenttracks&user=${LASTFM_USERNAME}&api_key=${LASTFM_API_KEY}&format=json&limit=1`);
        const data = await response.json();
        const track = data.recenttracks.track[0];
        
        if (track) {
          setLastTrack({
            name: track.name,
            artist: track.artist['#text'],
            album: track.album['#text'],
            image: track.image[3]['#text'], // Large size image
            url: track.url // Last.fm URL for the track
          });
        }
      } catch (error) {
        console.error('Error fetching last track:', error);
      }
    };

    fetchLastTrack();
    const interval = setInterval(fetchLastTrack, 30000); // Update every 30 seconds

    return () => clearInterval(interval);
  }, []);

  const openInSpotify = () => {
    if (lastTrack) {
      const searchQuery = encodeURIComponent(`${lastTrack.name} ${lastTrack.artist}`);
      window.open(`https://open.spotify.com/search/${searchQuery}`, '_blank');
    }
  };

  if (!lastTrack) return null;

  return (
    <div className="currently-playing">
      <h3>Last Played</h3>
      <div className="track-info">
        <img src={lastTrack.image} alt={`${lastTrack.name} album art`} />
        <div className="track-details">
          <p className="track-name" onClick={openInSpotify}>
            {lastTrack.name}
          </p>
          <p className="track-artist">{lastTrack.artist}</p>
          <p className="track-album">{lastTrack.album}</p>
        </div>
      </div>
    </div>
  );
}

export default CurrentlyPlaying;