// components/About.js
import React from 'react';

function About() {
  return (
    <section id="about">
      <p className="section__text__p1">Get To Know More</p>
      <h1 className="title">About Me</h1>
      <div className="section-container">
        <div className="section__pic-container">
          <img src="./assets/about-pic.png" alt="PFP" className="about-pic" />
        </div>
        <div className="about-details-container">
          <div className="about-containers">
            <div className="details-container">
              <img src="./assets/experience.png" alt="Experience icon" className="icon" />
              <h3>Experience</h3>
              <p>SWE @ RheoSense</p>
              <br />
              <p>SWE Intern @ Innowi</p>
              <br />
              <p>SWE Intern @ Milestone Technologies</p>
            </div>
            <div className="details-container">
              <img src="./assets/education.png" alt="Education icon" className="icon" />
              <h3>Education</h3>
              <p>B.S. Computer Science<br />UC Davis</p>
            </div>
          </div>
          <div className="text-container">
            <p>
              Hi, and welcome to my website! I am Omar Khan, a recent computer science graduate from
              the University of California, Davis. I am a software engineer based in the bay area with a passion for
              building effective solutions to the world's problems. I am currently a software engineer at RheoSense.
              Feel free to contact me if you have any questions, or
              would just like to chat, I would love to hear from you!
            </p>
          </div>
        </div>
      </div>
      <img 
        src="./assets/arrow.png" 
        alt="Arrow icon" 
        className="icon arrow" 
        onClick={() => document.getElementById('experience').scrollIntoView()} 
      />
    </section>
  );
}

export default About;