// components/Projects.js
import React from 'react';

function Projects() {
  return (
    <section id="projects">
      <p className="section__text__p1">Browse My Recent</p>
      <h1 className="title">Projects</h1>
      <div className="experience-details-container">
        <div className="about-containers">
          <div className="details-container color-container">
            <div className="article-container">
              <img src="/assets/project-1.png" alt="Project 1" className="project-img" />
            </div>
            <h2 className="experience-sub-title project-title">Detecting Flashes in Video Files Using ML</h2>
            <div className="btn-container">
              <a href="https://github.com/kkrachenfels/video-proc" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-color-2 project-btn">
                  Github Repo
                </button>
              </a>
              <a href="https://www.youtube.com/watch?v=bHZZutex_A0" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-color-2 project-btn">
                  Video Demo
                </button>
              </a>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="article-container">
              <img src="/assets/project-2.png" alt="Project 2" className="project-img" />
            </div>
            <h2 className="experience-sub-title project-title">AI trained to play Pong</h2>
            <div className="btn-container">
              <a href="https://colab.research.google.com/drive/1QzqIWe0FMEbrBkhvkC80CEBMOhu2OW05?usp=sharing" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-color-2 project-btn">
                  Colab
                </button>
              </a>
              <a href="/assets/pong.mp4" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-color-2 project-btn">
                  Live Demo
                </button>
              </a>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="article-container">
              <img src="/assets/project-3.png" alt="Project 3" className="project-img" />
            </div>
            <h2 className="experience-sub-title project-title">Emotion Detecting Facial Recognition</h2>
            <div className="btn-container">
              <a href="https://github.com/Omaaarkhan/faceEmotion" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-color-2 project-btn">
                  Github (private for security reasons)
                </button>
              </a>
              <a href="https://facialemotionrecognition.netlify.app/" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-color-2 project-btn">
                  Live Demo
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <img 
        src="/assets/arrow.png" 
        alt="Arrow icon" 
        className="icon arrow" 
        onClick={() => {
          const contactSection = document.getElementById('contact');
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }}
      />
    </section>
  );
}

export default Projects;